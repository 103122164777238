<template>
  <div class="downloadFile">
    <!-- 重命名 -->
    <a @click="download">{{ p_name }}</a>
    <!-- 不重命名 -->
    <!-- <a :href="src" @download="download" :download="p_name">{{ p_name }}</a> -->
    <!-- <a @download="download" :href="tmpUrl" download="源代码.sb3">{{
      p_name
    }}</a> -->
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import { Loading } from "element-ui";

export default {
  name: "downloadFile",
  props: {
    src: String,
    p_type: String, //类别 位置
    r_name: String,
    p_name:String
  },
  data() {
    return {
      tmpUrl: "",
      // p_name: "源代码", //改民资
    };
  },
  components: {
    // HelloWorld
  },
  methods: {
    download() {
      var url = this.src;

      /**
       * 获取 blob
       * @param  {String} url 目标文件地址
       * @return {cb}
       */
      function getBlob(url, cb) {
        var xhr = new XMLHttpRequest();
        xhr.open("GET", url, true);
        xhr.responseType = "blob";
        xhr.onload = function () {
          if (xhr.status === 200) {
            cb(xhr.response);
          }
        };
        xhr.send();
      }

      /**
       * 保存
       * @param  {Blob} blob
       * @param  {String} filename 想要保存的文件名称
       */
      function saveAs(blob, filename) {
        if (window.navigator.msSaveOrOpenBlob) {
          navigator.msSaveBlob(blob, filename);
        } else {
          var link = document.createElement("a");
          var body = document.querySelector("body");

          link.href = window.URL.createObjectURL(blob);
          link.download = filename;

          // fix Firefox
          link.style.display = "none";
          body.appendChild(link);
          link.click();
          body.removeChild(link);
          window.URL.revokeObjectURL(link.href);
        }
      }

      /**
       * 下载
       * @param  {String} url 目标文件地址
       * @param  {String} filename 想要保存的文件名称
       */
      function download(url, filename) {
        getBlob(url, function (blob) {
          saveAs(blob, filename);
        });
      }
      download(url, this.p_name);
    },
  },
  mounted() {
    // let type = this.p_type.split(" ")[0];
    // let index = parseInt(this.p_type.split(" ")[1]);
    // if (type == "yinjian") {
    //   switch (index) {
    //     case 0:
    //       this.p_name = "作品申报表";
    //       break;
    //     case 1:
    //       this.p_name = "作品电路图";
    //       break;
    //     case 2:
    //       this.p_name = "作品原创声明";
    //   }
    // } else if (type == "scratch") {
    //   switch (index) {
    //     case 0:
    //       this.p_name = "作品源代码";
    //       break;
    //     case 1:
    //       this.p_name = "作品辅助材料";
    //       break;
    //   }
    // }
  },
  watch: {
    // src: {
    //   immediate: true,
    //   handler: function (value) {
    //     console.log(value);
    //     let loadingInstance1 = Loading.service({ fullscreen: true });

    //     this.$serverLess
    //       .getTempFileURL({
    //         fileList: [value],
    //       })
    //       .then((res) => {
    //         loadingInstance1.close();
    //         console.log(res.fileList[0].tempFileURL);
    //         this.tmpUrl = res.fileList[0].tempFileURL;

    //         this.download();
    //       })
    //       .catch((err) => {
    //         loadingInstance1.close();
    //         this.$alert(`${err}`, "错误", {
    //           confirmButtonText: "确定",
    //         });
    //       });
    //   },
    // },

    // src: function (newVal, oldVal) {
    //   console.log(newVal)
    //   this.$serverLess
    //     .getTempFileURL({
    //       fileList: [newVal],
    //     })
    //     .then((res) => {
    //       console.log(res.fileList[0].tempFileURL);
    //       this.tmpUrl = res.fileList[0].tempFileURL;
    //     });
    // this.cData = newVal; //newVal即是chartData
    // },
    p_type: function (newVal, oldVal) {
      console.log(newVal);
    },
  },
};
</script>



<style scoped>
.downloadFile {
  margin: 10px;
}
a {
  text-decoration: underline;
  font-size: 18px;
}
</style>