<template>
  <div class="topBanner">
    <img class="logo" src="" alt />
    <div class="welcome">
      <div class="userName">欢迎您 {{ userName }}</div>
      |
      <el-button type="text" @click="loginOut">退出</el-button>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import { Loading } from "element-ui";

export default {
  name: "topBanner",
  props: {},
  data() {
    return {
      userName: "",
    };
  },
  components: {
    // HelloWorld
  },
  methods: {
    loginOut() {
      let loadingInstance = Loading.service();

      this.$localStorage.remove("login_status_token");
      this.$localStorage.remove("login_status_username");
      this.$localStorage.remove("choice");
      this.$localStorage.remove("fillBlank");
      this.$localStorage.remove("judges");
      this.$localStorage.remove("programming");
      this.$localStorage.remove("multipleChoice");
      this.loginState = false;
      setTimeout(() => {
        loadingInstance.close();
        // this.$router.push("/");
        let url =
          process.env.NODE_ENV === "development"
            ? "http://localhost:8080/"
            : "https://ai.nxcode.net";
        loadingInstance.close();

        window.open(url, "_self"); //跳转页面
      }, 500);
    },
  },
  mounted() {
    setTimeout(() => {
      // this.userName = this.$localStorage.get("steam_login_status").userName;
    }, 0);
  },
};
</script>



<style scoped>
.topBanner {
  background: #409eff;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}
.logo {
  width: 280px;
  padding: 18px;
}
.welcome {
  font-size: 16px;
  color: white;
  height: 99px;
  line-height: 99px;
  /* margin-right: 18px; */
  display: flex;
}
.userName {
  margin-right: 10px;
}
.el-button--text {
  color: white;
  margin: 10px;
}
</style>