<template>
  <div class="countdown">距离考试结束还有： {{ time }}</div>
</template>

<script>
export default {
  name: "countdown",
  props: {
    msg: String,
  },
  data() {
    return {
      time: "",
      second: 0,
    };
  },
  methods: {
    format(lefttime) {
      function perFix(num) {
        return num >= 10 ? num : "0" + num;
      }
      if (lefttime > 0) {
        var lefth = Math.floor((lefttime / (1000 * 60 * 60)) % 24), //计算小时数
          leftm = Math.floor((lefttime / (1000 * 60)) % 60), //计算分钟数
          lefts = Math.floor((lefttime / 1000) % 60); //计算秒数
        return perFix(lefth) + ":" + perFix(leftm) + ":" + perFix(lefts); //返回倒计时的字符串
      } else {
        setTimeout(() => {
          this.$emit("submit");
        }, 3000);
        return "时间到,即将自动交卷请勿操作";
      }
    },
  },
  mounted() {
    this.$http
      .post("/utils", {
        mode: "getEndTime",
      })
      .then((res) => {
        console.log(res.data);
        this.second = res.data;
        let daojishi = setInterval(() => {
          this.time = this.format((this.second -= 1000));
          if (this.time === "00:00:00") {
            this.time = "时间到";
            clearInterval(daojishi);
          }
        }, 1000);
      });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
