<template>
  <!-- 题目目录 -->
  <!--答题内容区域-->
  <div>
    <topBanner></topBanner>
    <div class="flex-containor">
      <div class="question-container">
        <div class="progress-area">
          <!-- <el-progress
            :text-inside="true"
            :stroke-width="20"
            :percentage="progressPercent"
            :status="progressStyle"
          ></el-progress> -->
          <countDown1 @submit="submitAuto" style="margin: 20px"></countDown1>
          <div class="cout_time"></div>
        </div>
        <!-- v-show="questionIndex === questionID" -->

        <div v-for="(subject, questionIndex) in subjects" :key="subject.id">
          <el-card class="box-card">
            <!-- 题目内容 -->
            <div >
              <span class="ques-head">题目{{ questionIndex + 1 }}：{{ subject.question }}</span>
            </div>
            <!-- 题目选项 -->

            <div v-if="subject.questionImg">
              <div class="input-box"></div>
              <h3>示意图</h3>
              <img :src="subject.img" :class="subject.imgSmall == true ? 'imgSmall' : 'imgBig'" alt />
            </div>


            <!-- <h3>附件</h3>
            <div class="uploadBox">
              <div v-for="(inner, index) in subject.appendix" :key="inner">
                <div v-if="inner != ''">
                  <div class="g-title_pull">
                    <span>附件{{ index + 1 }}</span>
                  </div>
                  <myfile
                    style="margin: 30px"
                    :src="inner"
                    :p_name="inner.split('/programming/')[1]"
                  ></myfile>
                </div>
              </div>
            </div> -->

            <!-- <el-radio-group v-model="checkedID" @change="getChoice(questionIndex,checkedID)"></el-radio-group> -->
          </el-card>
          <!-- <div class="right-container">
            <div class="buttons-group">
              <el-button type="primary" @click="prevSubject">上一题</el-button>
              <el-button type="primary" @click="nextSubject">下一题</el-button>
            </div>
          </div> -->
          <el-card class="box-card">
            <el-form ref="form" :model="form" label-width="80px" style="width: 60%">
              <el-form-item label="作品标题">
                <el-input v-model="form.name"></el-input>
              </el-form-item>
              <el-form-item label="作品说明">
                <el-input type="textarea" v-model="form.brief"></el-input>
              </el-form-item>
              <!-- <el-form-item label="操作说明">
                <el-input type="textarea" v-model="form.control"></el-input>
              </el-form-item> -->
            </el-form>

            <div class="uploadBox">
              <div v-for="inner in Number(subject.quesNum)" :key="inner">
                <div v-if="subject.quesNum == 1" class="g-title_pull">
                  <span>作品上传</span>
                </div>
                <div v-else class="g-title_pull">
                  <span>第{{ inner }}小问</span>
                </div>
                <el-upload ref="upload" :http-request="(response) => {
                    return submitFile(response, questionIndex, inner)
                  }
                  " :limit="1" :auto-upload="true" class="upload" drag action="#">
                  <span class="hitS red">(必需项)</span>
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">
                    <div class="hit">请上传作品</div>
                    <div>
                      （将文件拖到此处，或
                      <em>点击上传）</em>
                    </div>
                  </div>
                </el-upload>
                文件大小不可超过200M,请上传
              </div>
            </div>
            <div>
              <el-button type="primary" @click="submitAll" style="margin-left: 90px; margin-top: 20px">全部提交</el-button>
            </div>
          </el-card>
        </div>
      </div>
    </div>
  </div>

  <!-- 答题进度条 -->

  <!-- 功能按钮 -->
</template>

<script>
import topBanner from "../components/topBanner";

import myfile from "../components/tool/downloadFile";

import { returnQuestion, getUserMedia, submitAll } from "../utils/utils.js";

import { Loading } from "element-ui";

import countDown1 from "../components/countdown";

export default {
  name: "programming",
  data() {
    return {
      category: "",
      questionID: 0, //问题的索引值，默认显示第一题
      answer: "", //选项的索引值，未选择时为-1
      progressPercent: 0,
      progressStyle: "warning",
      subjects: [],
      ///////////////////
      state: "",
      time: "",
      //编辑器选项
      options: {
        language: "python",
        theme: "darcula",
        readOnly: false,
      },
      form: {
        brief: "",
        name: "",
        region: "",
        control: "",
      },
      content: "",
    };
  },
  props: {
    // category: String,
  },
  components: {
    topBanner,
    countDown1,
    myfile,
  },
  methods: {
    //点击下一题触发
    nextSubject() {
      //判断是否是最后一题
      if (this.questionID === this.subjects.length - 1) {
        this.questionID = this.subjects.length - 1;
        this.$message({
          message: "已经是最后一题，确认无误可以点击提交",
          center: true,
          type: "warning",
          showClose: true,
          duration: 1000,
        });
      } else {
        this.questionID += 1;
      }

      //将本题得答案赋值
      document.body.scrollTop = document.documentElement.scrollTop = 0;

      this.showChoice(this.questionID);
    },
    //点击上一题触发
    prevSubject() {
      //判断是否是第一题
      if (this.questionID === 0) {
        this.questionID = 0;
        this.$message({
          message: "已经是第一题",
          center: true,
          type: "warning",
          showClose: true,
          duration: 1000,
        });
      } else {
        this.questionID -= 1;
      }

      document.body.scrollTop = document.documentElement.scrollTop = 0;

      this.showChoice(this.questionID);
    },
    //每次点击选项都触发，获取题号和选项，并放到store中保存
    contentChange(answer) {
      this.myCode = answer;
      //////////////////////////////////------------
      console.log(answer);

      var programming = {
        quesIndex: this.questionID,
        answer: answer,
      };

      this.$store.commit("updateRecord", {
        choice: programming,
        type: "programming",
      });

      this.changeProgress();
    },
    //这里上传文件，需要修改
    submitFile(res, questionIndex, index) {
      //scracth涉及
      console.log("here", res, index);
      let loadingInstance = Loading.service();
      let username = this.$localStorage.get("login_status_username");
      let examID = this.$localStorage.get("examID");
      //上传文件到文件夹下
      this.$serverLess
        .uploadFile({
          // 云存储的路径  szs需要改/
          cloudPath: `online_work/${examID}/${username}/${questionIndex + 1
            }_${index}_${res.file.name}`,
          // 需要上传的文件，File 类型
          filePath: res.file,
          //有问题this.fileList[i][0].raw
        })
        .then((res) => {
          // 返回文件 ID
          console.log(res.fileID);
          let answer;

          try {
            answer =
              this.$localStorage.get("programming")[questionIndex].answer;
          } catch (err) {
            answer = [];
          }

          console.log("answer", answer);

          if (answer instanceof Array) {
            answer[index - 1] = res.fileID;
          } else {
            answer[index - 1] = res.fileID;
          }

          var programming = {
            quesIndex: this.questionID,
            answer: answer,
          };

          console.log(programming);
          this.$store.commit("updateRecord", {
            choice: programming,
            type: "programming",
          });
          this.changeProgress();
          loadingInstance.close();
          this.$message({
            message: "上传成功",
            center: true,
            type: "success",
            showClose: true,
            duration: 1500,
          });
        })
        .catch((err) => {
          loadingInstance.close();
          this.$message({
            message: "上传失败" + err,
            center: true,
            type: "error",
            showClose: true,
            duration: 1500,
          });
        });
    },
    //每次显示题目的同时获取该题目已选择哪一项并显示
    showChoice(id) {
      var programming = this.$store.state.programming;
      let flag = false;
      console.log(programming);
    },
    //更新进度条长度
    changeProgress() {
      var storeLen = (() => {
        let len = 0;
        this.$store.state.programming.forEach((item) => {
          if (item != null) {
            len++;
          }
        });
        return len;
      })();
      var subjectsLen = this.subjects.length;
      this.progressPercent = Math.floor((storeLen / subjectsLen) * 100);
      if (this.progressPercent === 100) {
        this.progressStyle = "success";
      }
    },
    goToQues(index) {
      this.questionID = index - 1;
      this.showChoice(this.questionID);
    },
    //提交并评分
    async submitAll() {
      this.$confirm("确定提交题目？一经提交题目将无法修改", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {

          this.$alert('恭喜您已完成预选赛，请关注“宁夏青少年科技竞赛”微信公众号，查询入围总决赛名单', '提示', {
            confirmButtonText: '确定',
            callback: action => {
              let url = "https://ai.nxcode.com";
              window.open(url, "_self");
              return;
            }
          });

          //把题目编号传过去
          this.form.questionID = this.questionID
          let subject = await submitAll({ type: "programming", form: this.form }); //获取题目
        })
        .catch((err) => {
          this.$message({
            type: "info",
            message: "提交失败" + err,
          });
        });
    },
    async submitAuto() {

      //把题目编号传过去
      this.form.questionID = this.questionID
      await submitAll({ type: "programming", form: this.form }); //获取题目
    },
    dataInit() {
      this.$store.state.scorePerQues = Math.floor(100 / this.subjects.length);
      //从缓存中获取题目，并显示
      this.$store.state.programming = this.$localStorage.get("programming");
      let programming = this.$store.state.programming;
      //如果是空的那么就不进行下面的渲染
      if (programming === null) return;
      this.showChoice(0);
      this.changeProgress();
    },
    outf(text) {
      var mypre = document.getElementById("output");
      mypre.innerHTML = mypre.innerHTML + text;
    },
    builtinRead(x) {
      if (
        Sk.builtinFiles === undefined ||
        Sk.builtinFiles["files"][x] === undefined
      )
        throw "File not found: '" + x + "'";
      return Sk.builtinFiles["files"][x];
    },
    runit(code) {
      this.$message("程序运行中");
      setTimeout(() => {
        var prog =
          code == "print('success')"
            ? "import turtle\r\nturtle.clear\r\nprint('success')"
            : this.myCode;
        // var prog = document.getElementById("yourcode").value;
        var mypre = document.getElementById("output");
        mypre.innerHTML = "";
        Sk.pre = "output";
        Sk.configure({
          output: this.outf,
          read: this.builtinRead,
          __future__: Sk.python3,
        });

        (Sk.TurtleGraphics || (Sk.TurtleGraphics = {})).target = "mycanvas"; //调整画布大小在skulpt-stdlib搜 width:600这里就可以修改了,width,height可以修改

        var myPromise = Sk.misceval.asyncToPromise(function () {
          return Sk.importMainWithBody("<stdin>", false, prog, true);
        });

        myPromise.then(
          (mod) => {
            Sk.configure({
              output: this.outf("success"),
              read: this.builtinRead,
              __future__: Sk.python3,
            });
            console.log("success");
          },
          (err) => {
            Sk.configure({
              output: this.outf("\n" + err.toString()),
              read: this.builtinRead,
              __future__: Sk.python3,
            });
            console.log(err.toString());
          }
        );
      }, 300);
    },
  },
  async mounted() {
    //获取科目
    // let routerCategory = this.$route.params.mode;
    // let beforeCategory = this.$localStorage.get("login_status_category");

    // if (routerCategory) {
    //   this.category = routerCategory;
    //   this.$localStorage.set("login_status_category", this.category);
    // } else {
    //   this.category = this.$localStorage.get("login_status_category");
    // }


    this.category = "AI创作普及赛"
    console.log(this.category);
    let subject = await returnQuestion(this.category); //获取题目
    console.log("subject", subject.programming);

    this.subjects = subject.programming;

    //获取题号
    let questionID = this.$localStorage.get("kcs_questionID");

    // if (questionID && beforeCategory == this.category) {
    //   // 有题号直接赋值
    //   this.questionID = questionID;
    // } else {
    // 没题号给赋值
    // this.questionID = parseInt(this.subjects.length * Math.random() - 1);
    // this.questionID = this.questionID < 1 ? 1 : this.questionID;
    this.questionID = 0
    this.$localStorage.set("kcs_questionID", this.questionID);
    // }

    let video = document.getElementById("video");
    // let canvas = document.getElementById("canvas");
    // let context = canvas.getContext("2d");
    //成功回调
    function success(stream) {
      video.srcObject = stream;
      video.play();
    }
    //失败回调
    function error(error) {
      console.log("访问用户媒体失败");
    }
    //开启摄像头
    if (
      navigator.mediaDevices.getUserMedia ||
      navigator.getUserMedia ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia
    ) {
      getUserMedia({ video: { width: 400, height: 300 } }, success, error);
    } else {
      alert("不支持");
    }
    //实现拍照的功能
    // document.getElementById("snap").addEventListener("click", function() {
    //   context.drawImage(video, 0, 0, 500, 500);
    // });
    setTimeout(() => {
      this.dataInit();
    }, 0);
  },

  filters: {
    //将选项的索引值转化为ABCD...的选项
    numToChoice(num) {
      return String.fromCharCode(num + 65);
    },
  },
};
</script>



<style scoped>
/* ____________________________________ */
.flex-containor {
  width: 93%;
  margin: 50px auto 80px auto;
  display: flex;
  flex-wrap: wrap;
}

.question-container {
  width: 100%;
  margin: 20px;
}

.question-area {
  padding: 0 1rem;
  flex: 1;
}

.right-container {
  position: relative;
  padding: 40px 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  text-align: center;
  width: 100%;
  background: white;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.right-container_title {
  font-size: 15px;
  margin: 10px;
}

.right-container_title::before,
.right-container_title::after {
  color: #aaa;
  content: '—————';
  margin: 20px 10px;
}

.input-box {
  font-size: 16px;
}

.el-tag {
  width: 3.5rem;
  margin: 0.5rem;
}

/* 决定高度 */
.el-card {
  margin: 30px auto;
  /* min-height: 60vh; */
  text-align: left;
}

.ques-head {
  font-size: 1.6rem;
}

.input-text {
  height: 3rem;
  line-height: 3rem;
  font-size: 1.4rem;
  margin: 1rem 0;
}

.input-text input {
  margin-right: 1rem;
}

.box-card {
  max-width: 1200px;
}

/* 左侧题目说明 */
.box-card .imgSmall {
  margin: 10px auto;
  width: 50%;
}

.box-card .imgBig {
  width: 100%;
}

/* 编辑器 */
.right-container {
  text-align: left;
  width: 100%;
}

.console {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: inline-block;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 55%;
  height: 670px;
  background-color: #ecf8ff;
  border-left: 1px solid #87746d;
}

#mycanvas {
  width: 600px;
  height: 60%;
  min-height: calc(639px * 0.6);
  border-bottom: 1px solid #87746d;
}

#output {
  height: 20%;
  min-height: calc(639px * 0.3);
  background: #41444e;
  color: white;
  text-align: left;
  z-index: 99;
}

.button-tool {
  /* 避免被canvas盖住 */
  position: relative;
  z-index: 99999;
  height: 55px;
  padding: 18px 28px;
  background: #ecf8ff;
  text-align: left;
  border-top: 1px solid #87746d;
  overflow: hidden;
}

.buttons-group {
  position: relative;
  z-index: 666;
}

.mtk1 {
  color: black;
}

.uploadBox {
  margin: 30px 0 0 85px;
  grid-template-columns: repeat(5, 300px);
}

.g-title_pull {
  height: 50px;
  overflow: initial;
  text-align: left;
  padding-top: 20px;

  span {
    font-size: 28px;
    font-weight: bold;
    color: #1c2abe;
    position: relative;
  }

  span::before {
    content: '';
    position: absolute;
    width: 75%;
    height: 5px;
    border-radius: 5px;
    background: #1c2abe;
    margin-top: 35px;
    margin-left: 12.5%;
  }
}
</style>

<style>
.el-form-item__content {
  margin-left: 100px !important;
}

.el-upload-dragger {
  width: 220px;
}
</style>